import React from 'react';

// 飞机信息
const PlaneInfoContent = ({ planeInfo, planeAgeSwitch }) => {
	if (planeInfo.size > 0) {
		const planeTypeName = planeInfo.get('planeTypeName');
		const planeWidthCategory = planeInfo.get('planeWidthCategory');
		const planeAge = planeInfo.get('planeAge');
		return (
			<div className="model-title">
				{planeTypeName ? <span className="tit-item">计划机型：{planeTypeName}</span> : ''}
				{planeWidthCategory ? <span className="tit-item">类型：{planeWidthCategory}</span> : ''}
				{planeAgeSwitch && planeAge ? <span className="tit-item">机龄：{planeAge}年</span> : ''}
			</div>
		);
	}
	return null;
};

export default PlaneInfoContent;
