import React from 'react';
import Immutable from 'immutable';

import { CABIN_TYPE } from '../../../../constants/list/enums/flightComfort';

import { Tabs, TabList, Tab, TabPannels, TabPannel } from '../../common/Tabs';

type CabinInfo = {
	cabin: any;
};

type Props = {
	cabinInfoList: Immutable.List<Immutable.Record<CabinInfo>>;
	getCabinInfoDetail;
};

// 航班舱位舒适度
const CabinInfoListContent = ({ cabinInfoList, getCabinInfoDetail }: Props) => {
	if (cabinInfoList.size > 0) {
		return (
			<Tabs>
				<TabList className="cabin-comfort-tabs" tabListType="ul">
					{cabinInfoList.map((cabinInfo, index) => {
						const cabinValue = CABIN_TYPE[cabinInfo.get('cabin')]?.value || '经济舱';
						return (
							<Tab tabType="li" key={index}>
								{cabinValue}
							</Tab>
						);
					})}
				</TabList>
				<TabPannels>
					{cabinInfoList.map((cabinInfo, index) => {
						return <TabPannel key={index}>{getCabinInfoDetail(cabinInfo)}</TabPannel>;
					})}
				</TabPannels>
			</Tabs>
		);
	}
	return null;
};

export default CabinInfoListContent;
