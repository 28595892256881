import React from 'react'
import SliderImageItem from './SliderImageItem'
import SliderExplainItem from './SliderExplainItem'
import SliderDots from './SliderDots'

export default class Slider extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            currentIndex: 0
        }
        this.autoPlayFlag = null
        this.pageTurn = this.pageTurn.bind(this)
        this.autoPlay = this.autoPlay.bind(this)
        this.pausePlay = this.pausePlay.bind(this)
    }

    componentDidMount() {
        this.autoPlay()
    }

    componentWillUnmount() {
        this.pausePlay()
    }

    // p代表滑动几项
    pageTurn(p) {

        let _p = this.state.currentIndex + p
        if (_p < 0) {
            // 变成翻到最后一页
            _p = _p + this.props.items.size
        }
        if (_p >= this.props.items.size) {
            // 变成翻到第一页
            _p = _p - this.props.items.size
        }
        this.setState({ currentIndex: _p })
    }

    //自动播放，每五秒播放一张图
    autoPlay() {
        const timeout = this.props.timeout || 5000
        this.autoPlayFlag = setInterval(() => {
            this.pageTurn(1)
        }, timeout)
    }

    // 暂停播放
    pausePlay() {
        clearInterval(this.autoPlayFlag)
    }

    render() {
        const { items, renderSliderFragement, wraperClass } = this.props,
            currentIndex = this.state.currentIndex

        let count = items ? items.size : 0

        let getImage = () => {
            return items.map((item, index) => {
                if (renderSliderFragement) {
                    return <SliderImageItem sliderItem={item.toJS()} key={index} enter={this.pausePlay} leave={this.autoPlay} />
                } else {
                    return <SliderImageItem imgSrc={item.get('bigUrl')} key={index} enter={this.pausePlay} leave={this.autoPlay} />

                }
            })
        }

        let imgWidth = document.getElementsByClassName("comfort-imgs")[0] ? document.getElementsByClassName("comfort-imgs")[0].offsetWidth : 0,
            ulStyle = {
                transform: 'translate(' + -1 * imgWidth * currentIndex + 'px)',
            },
            animationClass = count > 1 ? `fade${currentIndex % 2}` : ''

        return (
            // <img src='https://pic.c-ctrip.com/flight_intl/list/demos/plane-model-img1.jpg' />
            <div className={wraperClass || 'comfort-explain'}>
                <div className="comfort-imgs">
                    <ul style={ulStyle} className={animationClass}>
                        {getImage()}
                    </ul>
                    {count > 1 ? <SliderDots count={count} currentIndex={currentIndex} pageTurn={this.pageTurn} /> : null}
                </div>
                {!renderSliderFragement ? <SliderExplainItem currentItem={items.get(currentIndex)} /> : null}
            </div>
        )
    }
}