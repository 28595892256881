import React from 'react';
import { connect } from 'react-redux';
import Immutable from 'immutable';
import { getFlightComfort, genFlightComfort, setEmptyFlightComfort } from '../../../../actions/list/popups';
import PopupFlightComfort from '../../../../components/list/popups/popupFlightComfort';
import Converter from '../../../../sources/list/converter';
import { globalSwitchSelector } from '../flight/baseSelector';

class PopupFlightComfortContainer extends React.Component {
	constructor(props) {
		super(props);
		this.clickTabTitle = this.clickTabTitle.bind(this);
	}

	getParams(currentFlightList, index) {
		let cabinList = [],
			currentSegmentSeq = this.props.currentSegmentSeq;

		for (let i = 0; i < this.props.priceList.size; i++) {
			let p = this.props.priceList.get(i),
				cabin = '';
			cabin = p.get('cabin').split('|');
			if (cabin.length > 1) {
				cabin = cabin[currentSegmentSeq];
			} else {
				cabin = cabin[0];
			}
			cabin = cabin.replace(/[@]/gi, '');
			cabin = cabin.split('-');
			if (cabin.length > 1) {
				cabinList.indexOf(cabin[index]) < 0 && cabinList.push(cabin[index]);
			} else {
				cabinList.indexOf(cabin[0]) < 0 && cabinList.push(cabin[0]);
			}
		}
		let flightNo;
		if (currentFlightList) {
			flightNo = currentFlightList.get('operateFlightNo') || currentFlightList.get('flightNo');
		}

		let params = {
			airlineCode: this.props.segment.get('airlineCode'),
			arrivalDate: Converter.extractDate(currentFlightList.get('arrivalDateTime')),
			cabinList: cabinList,
			departureCityCode: currentFlightList.get('departureCityCode'),
			arrivalCityCode: currentFlightList.get('arrivalCityCode'),
			departureDate: Converter.extractDate(currentFlightList.get('departureDateTime')),
			flightNoList: [flightNo],
			aircraftCode: currentFlightList.get('aircraftCode'),
		};
		return params;
	}

	componentWillUnmount() {
		this.props.genFlightComfort([]);
	}

	comparePropsKeys(keys, source, target) {
		return keys.every((k) => Object.is(source[k], target[k]));
	}

	componentDidUpdate(prevProps, prevState, snapshot) {
		let flightList = this.props.segment.get('flightList');
		let comfortPopupIndex = 0;
		const cacheKey = () => `${this.props.cacheKey}-${comfortPopupIndex}`;
		if (
			this.props.comfortPopupIndex <= flightList.size - 1 &&
			this.props.comfortPopupIndex >= 0 &&
			!this.comparePropsKeys(['comfortPopupIndex', 'cacheKey', 'segment'], prevProps, this.props)
		) {
			comfortPopupIndex = this.props.comfortPopupIndex;
			let currentFlightList = flightList.get(comfortPopupIndex);
			let params = this.getParams(currentFlightList, comfortPopupIndex);
			if (!currentFlightList.get('virtualFlight')) {
				this.props.getFlightComfort(params, cacheKey());
			}
		}
	}

	componentDidMount() {
		// 初始化请求接口
		let flightList = this.props.segment.get('flightList');
		let comfortPopupIndex = this.props.comfortPopupIndex;
		let cacheKey = `${this.props.cacheKey}-${comfortPopupIndex}`;
		// this.setState({ currentIndex: comfortPopupIndex });
		if (flightList && flightList.size > 0) {
			let currentFlightList = flightList.get(comfortPopupIndex),
				params = this.getParams(currentFlightList, comfortPopupIndex);
			if (!currentFlightList.get('virtualFlight')) {
				// 虚拟航班没有舒适度，不需要请求
				this.props.getFlightComfort(params, cacheKey);
			}
			// 相同字符串的hashCode不同
			// console.log(Immutable.Map(params).hashCode())
		}
	}

	clickTabTitle(index) {
		// this.setState({ currentIndex: index });
		this.props.changeComfortPopupIndex(index);
	}

	getTabTitle() {
		let tabTitle = [],
			flightList = this.props.segment.get('flightList'),
			duration = '';
		if (flightList && flightList.size > 0) {
			flightList.map((f) => {
				// place = `${f.get('departureCityName')} — ${f.get('arrivalCityName')}`
				duration = Converter.formatMinAsDayHourMin(f.get('duration'));
				tabTitle.push({
					departureCityName: f.get('departureCityName'),
					arrivalCityName: f.get('arrivalCityName'),
					duration: duration,
					virtualFlight: f.get('virtualFlight'),
				});
			});
		}

		return Immutable.fromJS(tabTitle);
	}

	render() {
		let comfortPopupIndex = this.props.comfortPopupIndex;
		let cacheKey = `${this.props.cacheKey}-${comfortPopupIndex}`;
		return (
			<PopupFlightComfort
				flightList={this.props.segment.get('flightList')}
				flightComfort={this.props.flightComfort.get(cacheKey) || Immutable.Map()}
				clickTabTitle={this.clickTabTitle}
				globalSwitch={this.props.globalSwitch}
				tabTitle={this.getTabTitle()}
				currentIndex={this.props.comfortPopupIndex}
			/>
		);
	}
}

const mapStateToProps = (state) => {
	return {
		flightComfort: state.getIn(['list', 'popups', 'flightComfort']),
		globalSwitch: globalSwitchSelector(state),
	};
};

const mapDispatchToProps = (dispatch) => ({
	getFlightComfort(params, cacheKey) {
		dispatch(getFlightComfort(params, cacheKey));
	},
	setEmptyFlightComfort(cacheKey) {
		dispatch(setEmptyFlightComfort(cacheKey));
	},
	genFlightComfort(comfort) {
		dispatch(genFlightComfort(comfort));
	},
});

export default connect(mapStateToProps, mapDispatchToProps)(PopupFlightComfortContainer);
