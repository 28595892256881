import React from 'react'

export default class SliderExplainItem extends React.Component {
    constructor(props) {
        super(props)
    }
    

    render() {
        const {currentItem} = this.props
        
        return (
            <div>
                 <div className="exp-title">{currentItem.get('title')}</div>
                 <div className="exp-cont">{currentItem.get('content')}</div>
            </div>
        )
    }
}