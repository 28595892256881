import React from 'react';
import shortid from 'shortid';
import Immutable from 'immutable';

import AirlineLogo from '@/src/components/Logo/AirlineLogo';
import VirtualLogo from '@/src/components/Logo/VirtualLogo';

import SliderTab from '../../../Base/SliderTab';
import Slider from '../slider/Slider';
import {
	DEVICE_TYPE,
	SEAT_COMFORT_CATEGORY,
	deviceTypeWrapper,
	seatCategoryWrapper,
} from '../../../../constants/list/enums/flightComfort';
import POPUP_MSG from '../popupMsg';
import { getIfShowBridgePercent } from '../../common/getIfShowBridgePercent';
import { getIfScopeDomestic } from '../../../../sources/list/scope';
import CabinInfoListContent from './CabinInfoListContent';
import PlaneInfoContent from './PlaneInfoContent';

const PopupFlightComfort = ({
	clickTabTitle,
	currentIndex: cidx,
	globalSwitch,
	flightComfort,
	tabTitle,
	flightList,
}) => {
	const DEVICE_TYPE_KEYS = Object.keys(DEVICE_TYPE.toJSON()),
		SEAT_COMFORT_CATEGORY_KEYS = Object.keys(SEAT_COMFORT_CATEGORY.toJSON());

	let punctualityInfo = flightComfort.get('punctualityInfo') || Immutable.fromJS({}),
		planeInfo = flightComfort.get('planeInfo') || Immutable.fromJS({}),
		cabinInfoList = flightComfort.get('cabinInfoList') || Immutable.fromJS([]),
		flightPictureList = flightComfort.get('flightPictureList') || Immutable.fromJS([]),
		planeAgeSwitch = globalSwitch.get('planeAgeSwitch'); //飞机机龄开关

	let currentIndex = 0;
	if (cidx <= flightList.size - 1 && cidx >= 0) currentIndex = cidx;
	// 准点率和延误时长
	let getComfortContent = (isDep, punctuality, delaytime, bridge) => {
		const showBridge = getIfShowBridgePercent(bridge);
		let isHasComfort = punctuality || delaytime || showBridge;
		if (isHasComfort) {
			return (
				<div className="rate-line">
					<i className="tag">{isDep ? '出发' : '到达'}</i>
					{punctuality ? <span>准点率：{punctuality}</span> : ''}
					{delaytime ? (
						<span>
							平均{delaytime > 0 ? '延误' : '提前'}：{Math.abs(delaytime)}分钟
						</span>
					) : (
						''
					)}
					{showBridge ? <span>廊桥率：{bridge}</span> : ''}
				</div>
			);
		}

		return null;
	};

	// 渲染数据
	//"entertainEquipment" : "天花板式tv",//影音娱乐设备  字符串有值代表有设备，没有值代表没有
	//"power" : "直流电源",//电源 字符串有值代表有设备，没有值代表没有
	let getCabinInfoDetail = (cabinInfo) => {
		//枚举转换
		let deviceTypeEnums = cabinInfo
				.filter((value, key) => value && DEVICE_TYPE_KEYS.includes(key))
				.map((value, key) => new deviceTypeWrapper(value, key)),
			seatCategoryEnums = cabinInfo
				.filter((value, key) => SEAT_COMFORT_CATEGORY_KEYS.includes(key))
				.map((value, key) => new seatCategoryWrapper(value, key));
		return (
			<div className="model-detail">
				<div className="seat">
					{seatCategoryEnums.valueSeq().map((wrapper) => wrapper.type.value.getLabel(wrapper.label))}
				</div>
				<div className="devices">
					{deviceTypeEnums.valueSeq().map((wrapper) => wrapper.type.value.getLabel(wrapper.label))}
				</div>
			</div>
		);
	};

	// 只有一条的时候不展示tab
	// 渲染tab每一条数据
	let getTabTitle = (tabTitle) => {
		return (
			<ul>
				{tabTitle.map((t, index) => {
					return (
						<li
							className={index == currentIndex ? 'tab active' : 'tab'}
							onClick={() => {
								clickTabTitle(index);
							}}
							key={shortid.generate()}
						>
							<strong>
								<span className="city">{t.get('departureCityName')}</span> —{' '}
								<span className="city">{t.get('arrivalCityName')}</span>
							</strong>
							<div className="sub">
								{t.get('virtualFlight') ? '行驶' : '飞行'}&nbsp;
								{t.get('duration')}
							</div>
						</li>
					);
				})}
			</ul>
		);
	};

	const flightInfo = flightList.get(currentIndex);
	const airlineCode = flightInfo.get('subMarketAirlineCode') || flightInfo.get('marketAirlineCode');
	const airlineName = flightInfo.get('subMarketAirlineName') || flightInfo.get('marketAirlineName');
	const operateAirlineCode = flightInfo.get('operateAirlineCode');
	const operateAirlineName = flightInfo.get('operateAirlineName');
    const isShare = getIfScopeDomestic() ? !!operateAirlineCode : !!operateAirlineName;
	const operateFlightNo = flightInfo.get('operateFlightNo');
	const virtualFlight = flightInfo.get('virtualFlight');
	const trafficType = flightInfo.get('trafficType');
	const flightNo = flightInfo.get('flightNo');
	const isLowCostCarrier = flightInfo.get('isLowCostCarrier');
	return (
		<div className="popups popup-comfortinfo" style={{ verticalAlign: 'top', textAlign: 'left' }}>
			<div className="title">
				航班舒适度信息
				<span className="hint">数据由飞友科技提供</span>
			</div>
			<div className="body">
				{tabTitle && tabTitle.size > 1 ? (
					<SliderTab
						parentClassName="popups-tabs"
						preActiveClass="pre-box"
						nextActiveClass="next-box"
						preDisabledClass="pre-box-disabled"
						nextDisabledClass="next-box-disabled"
						tabTitleLength={tabTitle.size}
						leftMargin={0}
						currentIndex={currentIndex}
						getTabTitle={getTabTitle(tabTitle)}
						handleCurrentStart={() => {}}
						maxItem={4}
						currentStart={0}
						preContent="&lt;"
						nextContent="&gt;"
					/>
				) : null}
				<div className="comfort-detail">
					<div className="airline-box">
						<div className="airline">
							<AirlineLogo className="logo" airlineCode={airlineCode} size="32" />
							<span className="name">
								{airlineName} {flightNo}
							</span>
						</div>
						{isShare && (
							<div className="shareline">
								<i className="arrow-share"></i>
								{virtualFlight && <VirtualLogo trafficType={trafficType} size="48" />}
								{!virtualFlight && operateAirlineCode && <AirlineLogo trafficType={operateAirlineCode} size="48" />}
								<span className="name">
									实际承运 {operateAirlineName} {operateFlightNo}
									<span className="hint">（请您前往实际承运的航司值机柜台办理登机手续）</span>
								</span>
							</div>
						)}
						{isLowCostCarrier && (
							<div className="shareline">
								<i className="arrow-share"></i>
								<span className="name">{POPUP_MSG.FLIGHT_COMFORT.CHEAP_CABIN}</span>
							</div>
						)}
					</div>
					{punctualityInfo.size > 0 ? (
						<div>
							<div className="delay-rate">
								{getComfortContent(
									true,
									punctualityInfo.get('departurePunctuality'),
									punctualityInfo.get('departureDelaytime'),
									punctualityInfo.get('departureBridge')
								)}
								{getComfortContent(
									false,
									punctualityInfo.get('arrivalPunctuality'),
									punctualityInfo.get('arrivalDelaytime'),
									punctualityInfo.get('arrivalBridge')
								)}
							</div>
							<div className="delay-explain">
								<div className="explain">准点率：航班最近30次准点出发／到达情况。</div>
								{getIfShowBridgePercent(punctualityInfo.get('departureBridge')) ||
								getIfShowBridgePercent(punctualityInfo.get('arrivalBridge')) ? (
									<div className="explain">廊桥率：航班停靠航站楼廊桥的概率。</div>
								) : null}
							</div>
						</div>
					) : null}

					{planeInfo.size > 0 || cabinInfoList.size > 0 ? (
						<div className="plane-model">
							<PlaneInfoContent {...{ planeInfo, planeAgeSwitch }}></PlaneInfoContent>
							<CabinInfoListContent {...{ cabinInfoList, getCabinInfoDetail }}></CabinInfoListContent>
						</div>
					) : null}
				</div>

				{flightPictureList.size > 0 ? <Slider items={flightComfort.get('flightPictureList')} /> : null}
				{/* {flightPictureList.size > 0 ? <div className="disclaimer">图片仅供参考，以实际乘坐的航班情况为准</div> : null} */}
				<div className="disclaimer">机型仅供参考，以航空公司当天实际安排为准，如有变动，敬请谅解</div>
			</div>
		</div>
	);
};

export default PopupFlightComfort;
