import React from 'react'

export default class SliderImageItem extends React.Component {
    constructor(props) {
        super(props)
        this.enter = this.enter.bind(this)
        this.leave = this.leave.bind(this)
    }

    enter() {
        this.props.enter()
    }

    leave() {
        this.props.leave()
    }

    render() {
        const {imgSrc, sliderItem} = this.props
        return (
            <li onMouseEnter={this.enter} onMouseLeave={this.leave}>
                {sliderItem || <img src={imgSrc} alt=""/>}
            </li>
        )
    }
}
