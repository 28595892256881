import React from 'react'

export default class SliderDots extends React.Component {
    constructor(props) {
        super(props)
        this.handleDotClick = this.handleDotClick.bind(this)
    }

    handleDotClick(i) {
        let p = i - this.props.currentIndex
        this.props.pageTurn(p)
    }


    render() {
        const { count, currentIndex } =  this.props

        let getDots  = () => {
            let dotNodes = []
            for(let i = 0;i < count;i++) {
                dotNodes[i] = <i className={i == currentIndex ? 'circle active' : 'circle'} onClick={() => this.handleDotClick(i)} key={i}></i>
            }
            return dotNodes
        }
        
        return (
            <div className="circle-idx">
                {getDots()}
            </div>
        )
    }
}